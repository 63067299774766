@use 'variables';

.swal2-container {
  z-index: 9999 !important;

  .swal2-popup {
    .swal2-actions {
      button {
        &,
        &:focus {
          text-align: center;
          opacity: 1;
          display: inline-block;
          margin: 5px;
          padding: 5px 20px;
          font-size: 16px !important;
          font-weight: bolder;
          white-space: nowrap;
          min-width: 100px;
          cursor: pointer;
          border-radius: 20px !important;
          box-shadow: none;
        }
  
        &.swal2-styled {
          &.swal2-cancel {
            &,
            &:disabled,
            &:hover {
              border: 1px solid variables.$gray-cancel-button;
              color: variables.$gray-cancel-text;
              background: white;
            }
            
            &:focus,
            &:hover {
              border: 1px solid variables.$green-primary;
              color: variables.$green-primary;
              background: white;
            }
          }
        }
      }
    }
  
    .swal2-textarea {
      resize: none !important;
      height: 160px !important;
      font-size: 14px !important;
      border-radius: 6px !important;
  
      &:focus {
        border: 1px solid variables.$green-primary !important;
        box-shadow: none !important;
      }
    }

    .error-code {
      font-size: 12px;
      color: variables.$gray-dark;
      transition: all ease-in-out 250ms;
      opacity: 0;
      animation: fade-in 2.5s forwards;
    }


    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  &.swal2-shown {
    z-index: 1600;
  }
}

.swal-dialog {
  .swal2-content {
    & > #swal2-content {
      font-size: 18px !important;
    }
  }
} 

.swal-footer-text {
  font-size: 1.4em;
}

.swalPraise {
  border-radius: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 700px !important;
  height: 500px !important;
}

.swal-import {
  width: 60% !important;

  pre {
    color: variables.$red-primary;
    margin-top: 15px;
    text-align: left;
  }
}

.swal-large-font {
  font-size: 16px !important;

  strong {
    font-size: 18px !important;
  }
}

.swal-mobile {
  padding: 5px;
  
  .swal2-image {
    margin-top: 0;
    margin-bottom: 0;
  }

  .swal2-textarea {
    height: 100px !important;
  }
}
