@use 'variables';

.frankli-form-group {
  text-align: left;
  color: initial;
  font-weight: normal;

  & > label {
    white-space: nowrap;
    overflow: visible;
    position: relative;
    top: 24px;
    font-weight: 700 !important;
    color: variables.$gray-text-light;
  }

  & > .form-control {
    -webkit-text-fill-color: variables.$gray-text;
  }

  // Hide all placeholders by default
  input,
  textarea {
    &::-webkit-input-placeholder,
    &::-webkit-input-placeholder {
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    &:-ms-input-placeholder,
    &:-ms-input-placeholder {
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    &::-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    &::placeholder,
    &::placeholder {
      color: transparent;
      -webkit-text-fill-color: transparent;
    }
  }


  .box-left,
  .box-right {
    border: none;
    border-radius: 0px;
    border-bottom: 1px variables.$gray-dark solid;
  }

  .input-group-addon {
    border: none;
    border-radius: 0px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: variables.$gray-dark;
  }

  // Textarea styles
  .fake-textarea,
  textarea {
    outline: none;
    border: 1px variables.$gray-dark solid;
    border-radius: 6px;

    &:focus {
      border: 1px variables.$green-primary solid;
      border-radius: 6px;
      // background: rgba(0, 0, 0, 0.02);
    }

    &.single-line-textarea {
      border: none;
      border-bottom: 1px solid variables.$gray-dark;
      border-radius: 0px;
      background: transparent;
      resize: none;
      min-height: 40px;
      height: 40px;
      transition: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      &:focus {
        border-bottom: 1px variables.$green-primary solid;
        background: rgba(0, 0, 0, 0.02);
      }
    }
  }

  // Regular input styling
  .fake-input,
  input:not(.round-search) {
    border: none;
    border-radius: 0;
    border-bottom: 1px variables.$gray-dark solid;
    min-height: 40px;
    background: transparent;
    -webkit-text-fill-color: variables.$gray-text;

    &:focus {
      border: none;
      border-bottom: 1px variables.$green-primary solid;
    }
  }

  // Select input styling
  .fake-select,
  select {
    /* Removes the default <select> styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px variables.$gray-dark solid;
    background: transparent;
    /* Positions background arrow image */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: right;
    background-origin: content-box;
    padding: 8px 5px;

    &:focus {
      border: none;
      box-shadow: none;
      border-bottom: 1px variables.$green-primary solid;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 variables.$black-primary;
    }
  }

  // General form control styles
  .form-control {
    &[disabled] {
      background-color: variables.$gray-ultralight;
      cursor: not-allowed;
      color: variables.$gray-text;
    }
    &[readonly] {
      cursor: text;
    }

    &select[disabled] {
      background-color: transparent !important;
      background-position-x: right;
      background-position-y: center;
      background-repeat: no-repeat;
    }

    &select[multiple] {
      border: 1px variables.$gray-dark solid;
      border-radius: 6px;
      overflow: hidden;
      background-image: none;

      &:focus {
        border: 1px variables.$green-primary solid;
      }
    }
  }

  &:focus-within {
    border-color: variables.$green-primary;

    label {
      /* color: variables.$green-primary; */
      top: 0;
      transition: top 100ms;
    }

    .box-left,
    .box-right {
      border-color: variables.$green-primary;
      color: variables.$green-primary;
    }

    input.single-line-textarea,
    &:not(.frankli-form-group-invalid) .input-group-addon {
      border-bottom-color: variables.$green-primary;
    }
  }

  &.frankli-form-group-invalid {
    .fake-input,
    input:not(.round-search),
    .fake-select,
    select,
    .fake-textarea,
    .search-input-container,
    textarea {
      color: initial !important;
      border-color: variables.$red-primary !important;
    }

    .box-left,
    .box-right {
      border-color: variables.$red-primary !important;
      color: variables.$red-primary !important;
    }
  
    .input-group-addon {
      border-bottom-color: variables.$red-primary !important;
    }
  }

  &.frankli-form-group-placeholder-shown {
    input::placeholder,
    textarea::placeholder {
      color: variables.$gray-dark;
      -webkit-text-fill-color: variables.$gray-text-light;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: variables.$gray-dark;
      -webkit-text-fill-color: variables.$gray-text-light;
    }

    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
      color: variables.$gray-dark;
      -webkit-text-fill-color: variables.$gray-text-light;
    }

    input::-ms-input-placeholder,
    textarea::-ms-input-placeholder {
      color: variables.$gray-dark;
      -webkit-text-fill-color: variables.$gray-text-light;
    }

  }

  &.frankli-form-group-filled {
    label {
      top: 0;
    }
  }
}

.frankli-form-group-alert {
  color: variables.$red-primary;
  text-align: center;
  min-height: 20px;
  background: none;
  font-weight: 600;
}

.frankli-form-group-counter {
  margin-top: -20px;
  text-align: right;
}
