
.standard-heading {
  display: block;
  margin: 0;
  font-weight: 600;
}

h1.standard-heading {
  font-size: 24px;
  line-height: 40px;
}
h2.standard-heading {
  font-size: 22px;
  line-height: 40px;
}
h3.standard-heading {
  font-size: 20px;
  line-height: 40px;
}
h4.standard-heading {
  font-size: 18px;
  line-height: 20px;
}
h5.standard-heading {
  font-size: 16px;
  line-height: 20px;
}
h6.standard-heading {
  font-size: 14px;
  line-height: 20px;

  // Remove capitalization
  text-transform: none;
}
