$green-primary: #30747F;
// $green-contrast: #00353D;
$green-contrast: #66A0AB;
$green-secondary: #BBE2BE;
$green-light: #DCE3E3;
$green-ultralight: #EEF0EF;

$red-primary: #FB946E;
$red-secondary: #FD384E;

$yellow-primary: #FFC200;
$yellow-secondary: #fCC559;
$yellow-tertiary: #FFEA83;

$blue-primary: #254DC6;

$gray-background: #F7F7F7;
$gray-primary: #D3D3D3;
$gray-secondary: #E6EDEC;
$gray-ultralight: #F5F5F5;
$gray-light: #EEEEEE;
$gray-mid: #DDDDDD;
$gray-dark: #AAAAAA;
$gray-cancel-button: #A3A5AA;
$gray-cancel-text: #222222;
$gray-text: #0C1D31;
$gray-text-dark: #010000;
$gray-text-mid: #5B5957;
$gray-text-light: #888888;

$black-primary: #000000;
$navy-primary: #012139;
$white-primary: #FFFFFF;
$offwhite-primary: #F7F6F5;

// Statuses
$status-red: $red-primary;
$status-navy: #012139;
$status-gray: $gray-mid;
$status-yellow: $yellow-primary;
$status-green: #30747F;
$status-blue: #03468C;

// Pill Colours
$pill-yellow-background: #FCECB3;
$pill-yellow-text: #DCA700;

$pill-green-background: #ABD5D9;
$pill-green-text: #30747F;

$pill-red-background: #FFCFAF;
$pill-red-text: #C26A49;

$pill-blue-background: #8DB7EE;
$pill-blue-text: #002E6A;

$pill-navy-background: #BBC4D0;
$pill-navy-text: #2F3E4F;

$pill-gray-background: #EBE9E6;
$pill-gray-text: #5B5957;

$pill-offwhite-background: $gray-background;
$pill-offwhite-text: $gray-text;

//  Other products

$microsoft-gray: #2F2F2F;
$teams-purple: #6264A7;
$twitter-blue: #1DA1F2;
$linkedin-blue: #0072b1;

// Likert and rating scales
// Top to bottom
$rating-5: #008168;
$rating-4: #54C6BB;
$rating-3: #FFC200;
$rating-2: #f75757;
$rating-1: #d0352c;

// Profile photo
$profile-photo-background: #556080;
$profile-photo-text: #e7eced;