@use "./assets/css/variables";
@use "./assets/css/breakpoints";

@use "./assets/css/light-bootstrap-dashboard";

@use "./assets/fontawesome/css/all.min.css";

@use "./assets/css/fonts";
@use "./assets/css/flex";
@use "./assets/css/tables";
@use "./assets/css/margins";
@use "./assets/css/paddings";
@use "./assets/css/buttons";
@use "./assets/css/richLink";
@use "./assets/css/forms";
@use "./assets/css/slider";
@use "./assets/css/sweetalerts";
@use "./assets/css/tooltips";
@use "./assets/css/hover-utils";
@use "./assets/css/headings";
@use "./assets/css/text";
@use "./assets/css/cards";
@use "./assets/css/line-heights";
@use "./assets/css/sentry";
@use "./assets/css/printing";
@use "./assets/css/draganddrop";
@use "./assets/css/min-widths";

html,
body {
  height: 100%;
}

body  {
  position: fixed;
  top: inherit;

  height: 100%;
  width: 100%;

  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

.alert-frankli-blue {
  background-color: variables.$green-primary;
}

.interest {
  border: 1px solid variables.$yellow-primary;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  margin: 10px;
  display: inline-block;
  text-transform: none;
  border-radius: 5px;
  font-size: 11px;

  a {
    color: variables.$pill-yellow-text;
  }

  &:hover {
    cursor: pointer;
    background-color: rgb(244, 245, 247);
    
    a {
      color: variables.$pill-yellow-text;
    }
  }
}

// TODO: Think this is unused
.center {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  supported by Chrome and Opera */
}

.clickable {
  cursor: pointer;
}

.icon-align {
  font-size: 20px;
  vertical-align: sub;
}

.badge-info {
  background-color: variables.$green-primary;
}

.badge-danger {
  background-color: variables.$red-primary !important;
}

.badge-success {
  background-color: variables.$green-secondary;
}

.icon-info:hover {
  color: variables.$green-primary;
}

.icon-info {
  color: grey;
}

.icon-danger:hover {
  color: variables.$red-primary !important;
}

.icon-danger {
  color: grey;
}

.list-group-item-action:hover {
  background: variables.$offwhite-primary;
  cursor: pointer;
}

.wrap-overflow {
  overflow-wrap: break-word;
}

.modal-content-ngx {
  width: 500px !important;
  height: 350px !important;
}

.font-weight-bold {
  font-weight: bold;
}

.md-avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.small-col {
  width: 5%;
}

.wrap-text {
  word-wrap: break-word;
}

.btn-140 {
  width: 140px !important;
}

.btn-160 {
  width: 160px !important;
}

.icon-padding {
  padding: 15px;
}

.alert [data-notify="icon"][class*="fa"]~[data-notify="message"] {
  padding-left: 40px;
}

.alert [data-notify="icon"][class*="pe"]~[data-notify="message"] {
  padding-left: 40px !important;
}

.alert button.close {
  opacity: 0.8 !important;
}

.nv-pie-title {
  text-anchor: middle !important;
  font-size: 32px !important;
  fill: variables.$gray-text-dark !important;
}

.lock-height {
  height: 144px !important;
}

.new-icon-height {
  height: 150px !important;
}

.progress-bar-info {
  background-color: variables.$green-primary;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.alert-danger,
.alert-error {
  background-color: variables.$red-primary !important;
}

.alert-warning,
.alert-warn {
  background-color: variables.$yellow-primary !important;
}

.text-info, .text-info:hover {
  color: variables.$green-contrast !important;
}

.text-grey {
  color: variables.$gray-text-light;
}

.goal-type-icon {
  font-size: 24px;
  color: variables.$gray-cancel-button;
  width: 30px;
}

/* --- START FORM CLASSES --- */

.form-text-wrap {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-group.has-error.has-error-frankli {
  margin-bottom: 0px !important;
}

.form-group.has-error.has-error-frankli>.form-control {
  color: variables.$red-primary !important;
  border-color: variables.$red-primary !important;
}

.form-group.has-error.has-error-frankli>input.form-control {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.form-group.has-error.has-error-frankli>select.form-control {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.alert.alert-frankli {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 0px;
}

/* --- END FORM CLASSES --- */

.fa.frankli-icon {
  margin-right: 5px;
  margin-left: 5px;
  font-size: 18px;
}

.fa.frankli-icon:hover {
  cursor: pointer;
  transform: scale(1.3);
}

.fa.frankli-icon.frankli-icon-info {
  color: variables.$green-contrast;
}

.fa.frankli-icon.frankli-icon-danger {
  color: variables.$red-primary;
}

/* Button Classes */
.admin-icon {
  height: 150px !important;
}

/* Buttons */

.fal {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}

.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 300;
}

/* border radius classes */

.b-radius-10 {
  border-radius: 10px !important;
}

.b-radius-20 {
  border-radius: 20px !important;
}

.b-radius-30 {
  border-radius: 30px !important;
}

/* rounded search */

.round-search {
  border-radius: 30px;
  overflow: hidden;
}

.round-search>i {
  padding-right: 20px;
}

i.fa-search.form-control-feedback {
  margin-right: 20px;
}

/* Breadrumb colouring */

.breadcrumb>li>a {
  color: variables.$green-contrast;
}

.breadcrumb>li>a:hover,
.breadcrumb>li>a:focus {
  color: variables.$green-contrast
}

/* Help links */

.help-link {
  font-size: 16px;
  color: variables.$green-contrast;
}

.help-link:hover,
.help-link:focus {
  color: variables.$green-contrast;
}

// .card {
//   margin-bottom: 15px;
//   box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
// }

/* Nav tabs colouring */

.nav .nav-link {
  color: variables.$gray-text-light
}

.nav .nav-link:hover {
  color: variables.$green-contrast
}

.nav-open .nav .caret {
  border-bottom-color: variables.$white-primary;
  border-top-color: variables.$white-primary;
}

/* Administrate User */

.admin-card {
  min-height: 270px;
  height: 100%;
  padding: 10px;
  position: relative;
}

.admin-card-sm {
  min-height: 200px;
  height: 100%;
  padding: 10px;
  position: relative;
}

.admin-card .step-message, .admin-card-sm .step-message {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: variables.$green-contrast;
}

.admin-card .step-icon.step-complete, .admin-card-sm .step-icon.step-complete {
  color: variables.$green-contrast;
}

.admin-card .step-icon.step-incomplete, .admin-card-sm .step-icon.step-incomplete {
  color: variables.$red-primary;
}

.admin-card:hover {
  background-color: variables.$gray-light;
  cursor: pointer;
}

.admin-card-sm:hover {
  background-color: variables.$gray-light;
  cursor: pointer;
}

.admin-card.admin-card-disabled:hover {
  cursor: not-allowed;
}

.admin-card-sm.admin-card-disabled:hover {
  cursor: not-allowed;
}

.admin-card .admin-card-icon {
  height: 150px;
}

.admin-card-sm .admin-card-icon-sm {
  height: 100px;
}

.admin-card .admin-card-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
}

.admin-card.admin-card-only-title .admin-card-title {
  margin-top: 15px;
}

.admin-card-sm .admin-card-title-sm {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.admin-card-sm .admin-card-description {
  font-size: 12px;
}

.admin-card-sm.admin-card-only-title .admin-card-title-sm {
  margin-top: 10px;
}

a {
  color: variables.$green-primary;

  &:hover,
  &:focus {
    color: variables.$green-contrast;
  }
}

.breadcrumb {
  padding-left: 5px;
  margin-bottom: 10px;
}

.card-dark {
  background-color: variables.$navy-primary !important;
  border-radius: 0 !important;
  color: variables.$white-primary !important;
}

.scroll-x {
  overflow-x: auto;
}

.d-inline-block {
  display: inline-block;
}

/* Textarea resize classes */

.vresize {
  resize: vertical;
}

.hresize {
  resize: horizontal;
}

.noresize {
  resize: none;
}

span.desc-width p {
  font-size: 14px !important;
}

.popup-competency-description p {
  font-size: 12px !important;
}

.sidebar-competency-description p {
  font-size: 14px !important;
}

.modal {
  // NOTE: Notify toasts show at 1550 so this is just under them
  z-index: 1549;
  // z-index: 1600;
}

/* Hide default HTML checkbox */

.switch input {
  display: none;
}

/* The slider */

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 24px;
}

.label-fix {
  height: 30px;
  line-height: 30px;
}

.goal-status-highlight-off-track {
  border-left-color: variables.$status-red !important;
}

.goal-status-highlight-progressing {
  border-left-color: variables.$status-yellow !important;
}

.goal-status-highlight-on-track {
  border-left-color: variables.$status-green !important;
}

.goal-status-highlight-complete {
  border-left-color: variables.$pill-blue-text !important;
}

.goal-status-highlight-archived {
  border-left-color: variables.$gray-primary !important;
}

.disabled {
  pointer-events: none;
}

.hidden {
  display: none !important;
}

/* #endregion */

.owner-img {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  line-height: normal;
  vertical-align: middle;
}

.text-wrap {
  white-space: normal !important;
}

.checkbox>label {
  user-select: none;
}

.p-relative {
  position: relative;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 100;
  border-top: 1px solid variables.$gray-light;
  padding: 10px 0;
}

.fake-date-input[disabled] {
  cursor: not-allowed;
}

/* TODO: Implement where needed. Fix for safari setting overflow: visible on transform */

.safari-transform-fix {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.v-align-top {
  vertical-align: top;
}

.v-align-middle {
  vertical-align: middle;
}

.v-align-bottom {
  vertical-align: bottom;
}

/* #region - DATE PICKER */
.datepicker table tr td.disabled {
  color: lightgray !important;
}

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
  background-color: variables.$green-primary;
  color: variables.$white-primary;
}

.bootstrap-datetimepicker-widget table td span.active {
  background-color: variables.$green-primary;
  color: variables.$white-primary;
}

.datepicker table tr td {
  color: black;
}

/* selected day */

.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
  background: variables.$green-primary !important;
  color: variables.$white-primary !important;
}

/* #region - "Elegant" way to get create one to one date picker to have the arrow at the bottom */
.container-create-one-to-one .bootstrap-datetimepicker-widget {
  margin-bottom: 0px !important;
}

.container-create-one-to-one .bootstrap-datetimepicker-widget::before, .container-create-one-to-one .bootstrap-datetimepicker-widget::after {
  top: unset;
  transform: rotate(180deg);
}

.container-create-one-to-one .bootstrap-datetimepicker-widget::before {
  bottom: -11px;
}

.container-create-one-to-one .bootstrap-datetimepicker-widget::after {
  bottom: -10px;
}

/* Datepicker shape fix */
.timepicker.col-md-6 {
  height: 280px;
  position: relative;
}
.timepicker.col-md-6 .timepicker-picker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
}
.bootstrap-datetimepicker-widget .timepicker-picker,
.bootstrap-datetimepicker-widget .timepicker-hours,
.bootstrap-datetimepicker-widget .timepicker-minutes {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bootstrap-datetimepicker-widget .timepicker-minutes {
  width: 216px
}
.bootstrap-datetimepicker-widget .timepicker-picker span,
.bootstrap-datetimepicker-widget .timepicker-hours span,
.bootstrap-datetimepicker-widget .timepicker-minutes span {
  width: 56px;
}
.bootstrap-datetimepicker-widget .datepicker-days > table {
  width: unset;
}
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  line-height: 25px;
  width: 30px;
  height: 30px;
}

.bootstrap-datetimepicker-widget table td.day {
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  width: 36px !important;
  height: 36px !important;
  line-height: unset !important;
}

.bootstrap-datetimepicker-widget .picker-switch.accordion-toggle > table {
  width: 100%;
}
.bootstrap-datetimepicker-widget .timepicker-hours, .bootstrap-datetimepicker-widget .timepicker-minutes,
.bootstrap-datetimepicker-widget .timepicker-picker, .timepicker.col-md-6 .timepicker-picker {
  position: unset;
  top: unset;
  left: unset;
  transform: unset;
}
.bootstrap-datetimepicker-widget > li > .timepicker > .timepicker-picker > table {
  width: 100%;
}
/* #endregion */

.popover-bulk-upload-table-error {
  border: 1px solid variables.$red-primary;
}

.popover-bulk-upload-table-error.popover.top>.arrow::after {
  border-top-color: variables.$red-primary !important;
}

.popover-bulk-upload-table-warning {
  border: 1px solid variables.$yellow-primary;
}

.popover-bulk-upload-table-warning.popover.top>.arrow::after {
  border-top-color: variables.$yellow-primary !important;
}

.popover-bulk-upload-table-valid {
  border: 1px solid variables.$navy-primary;
  color: variables.$navy-primary;
}

.popover-bulk-upload-table-valid.popover.top>.arrow::after {
  border-top-color: variables.$navy-primary !important;
}

/* Display tinyMCE dropdowns above modals */
.tox-tinymce-aux {
  z-index: 1600 !important;
}
.tox-tinymce {
  border-radius: 6px !important;
}

.tox-tinymce * {
  touch-action: pan-x pan-y;
}

.tox .tox-editor-header {
  z-index: unset !important; // Stops editor toolbar from displaying over notes panel in 1:1s when it's pinned
}

/* #region - Date range picker */
.daterangepicker .ranges li.active,
.daterangepicker .drp-calendar .calendar-table td.active {
  background-color: variables.$green-primary;
}

/* #endregion */

.myDpSelectedDay, .myDpSelectedMonth, .myDpSelectedYear {
  background-color: variables.$white-primary !important;
  color: variables.$green-contrast !important;
  font-weight: bold !important;
}

.myDpDisabled {
  color: variables.$gray-mid !important;
  background: none !important;
}

.ng-mydp table td, .ng-mydp table th {
  color: black;
}

/* #region - button group */
.btn-group .btn {
  padding: 7px;
  border: 1px solid variables.$gray-mid;
  color: variables.$gray-text;
}

.btn-group .btn.btn-success {
  background: variables.$green-primary;
  color: variables.$white-primary;
}

.btn-group .btn.btn-frankli-green-inverted {
  border-color: variables.$green-contrast;
  color: variables.$green-contrast;
  background: white;
  z-index: 1;
}
/* #endregion */

/* Duotone icons */
.fad {
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1
}

/* Required for notifications to not overflow */
.rotate-90 {
  transform: rotate(90deg);
}

.button-margins button {
  margin-left: 5px;
  margin-right: 5px;
}


/* External link icon */
a[target="_blank"]:not(.no-external-icon)::after {
  content: "\f08e";
  font-family: "Font Awesome 5 Pro";
  position: relative;
  color: variables.$green-contrast;
  font-size: 1rem;
  padding-left: 5px;
  transition: all ease-in-out 250ms;
}

/* Fix for icon showing in notifys */
.alert[role="alert"] a[target="_blank"]::after {
  content: none;
}

/* FDS-3147 - Fix for artifact on datepicker */
.bootstrap-datetimepicker-widget table td.today::before {
  content: none;
}

/* #region - DROPDOWNS W/ CARET ON RIGHT */
.dropdown-menu.dropdown-menu-right {
  right: 0;
  left: unset;
}
.dropdown-menu.dropdown-menu-right::before,
.dropdown-menu.dropdown-menu-right::after {
  right: 12px;
  left: unset;
}
/* #endregion */

// #region - DROPDOWN BUTTON ICONS
.dropdown-button-menu-item {
  padding: 5px;
  background-color: variables.$white-primary;
  transition: all ease-in-out 250ms;
  cursor: pointer;

  &:hover {
    background-color: variables.$gray-light;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.dropdown-button-menu-divider {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: variables.$gray-light;
}
// #endregion

.noselect {
  user-select: none;
}

h4.title,
.h4.title {
  &:not(.standard-heading) {
    font-weight: 500;
  }
}

// Stops selected pagination page displaying over other items
.pagination > .active > a, .pagination > .active > a:focus,
.pagination > .active > a:hover, .pagination > .active > span,
.pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: unset;
}

.sidebar-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 10px;
}

.card-shadow {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 15px 15px;
}

hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

.h-100 {
  height: 100%;
}

@media print {
  body {
    position: relative;
    height: unset !important;
    overflow: unset;
  }
}

.flip-h {
  transform: scaleX(-1);
}

.flip-v {
  transform: scaleY(-1);
}

.myDpDaycell {
  &.meeting-date-scheduled,
  &.meeting-date-in-progress,
  &.meeting-date-didnt-happen,
  &.meeting-date-completed,
  &.meeting-date-scheduled {
    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      opacity: 0.1;
      border-radius: 6px;
      box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    }
  }

  &.meeting-date-scheduled::before {
    // background-color: variables.$pill-yellow-background !important;
    background-color: variables.$status-yellow;
  }
  &.meeting-date-in-progress::before {
    // background-color: variables.$pill-green-background;
    background-color: variables.$status-green;
  }
  &.meeting-date-didnt-happen::before {
    // background-color: variables.$pill-red-background;
    background-color: variables.$status-red;
  }
  &.meeting-date-completed::before {
    // background-color: variables.$pill-blue-background;
    background-color: variables.$status-blue;
  }

  &.myDpDisabled {
    .myDpDayValue {
      &.myDpMarkCurrDay {
        border-bottom: 2px solid variables.$gray-mid;
      }
    }
  }
}

input[type="checkbox"] {
  // accent-color: variables.$status-blue;
  accent-color: variables.$green-primary
}