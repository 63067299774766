/* TONDO */
@font-face {
  font-family: "Tondo Regular";
  src: url('../fonts/Tondo_W_Rg.woff') format("woff"),
      url('../fonts/Tondo_W_Rg.eot') format("truetype");
}

/* SOURCE SANS */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/source_sans_pro/SourceSansPro-ExtraLight.ttf') format("truetype");
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/source_sans_pro/SourceSansPro-ExtraLightItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/source_sans_pro/SourceSansPro-Light.ttf') format("truetype");
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/source_sans_pro/SourceSansPro-LightItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source_sans_pro/SourceSansPro-Regular.ttf') format("truetype");
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/source_sans_pro/SourceSansPro-Italic.ttf') format("truetype");
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/source_sans_pro/SourceSansPro-SemiBold.ttf') format("truetype");
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/source_sans_pro/SourceSansPro-SemiBoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/source_sans_pro/SourceSansPro-Bold.ttf') format("truetype");
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/source_sans_pro/SourceSansPro-BoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/source_sans_pro/SourceSansPro-Black.ttf') format("truetype");
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/source_sans_pro/SourceSansPro-BlackItalic.ttf') format("truetype");
}
