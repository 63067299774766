@use 'variables';

.rich-link {
  user-select: none;
  cursor: default;
  vertical-align: top;
  display: inline-block;
  line-height: 0px;
  border-radius: 6px;
}

.rich-link .rl-meta {
  display: none;
}

.rich-link .rl-data {
  position: relative;
  display: inline-block;
  background-color: variables.$white-primary;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: variables.$gray-light;
  padding: 5px;
  padding-left: 10px;
  user-select: none;
  cursor: default;
  line-height: 1.4;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
}

.rich-link .rl-data > span {
  display: inline-block;
}

.rich-link .rl-data .title {
  white-space: normal;
  min-width: 200px;
  max-width: calc(100% - 90px);
}

.rich-link:active {
}

.rich-link .rich-link-error {
  color: variables.$red-primary;
}

.rich-link .rich-link-error {
  color: variables.$yellow-primary;
}

/* #region - PILLS */
.rich-link .pill {
  display: inline-block;
  background: variables.$gray-mid;
  color: variables.$navy-primary;
  border-radius: 6px;
  white-space: nowrap;
  font-size: 12px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 4px;
  padding-right: 4px;
  vertical-align: top;
  user-select: none;
}
.rich-link .pill.yellow {
  background: variables.$pill-yellow-background;
  color: variables.$pill-yellow-text;
}
.rich-link .pill.green {
  background: variables.$pill-green-background;
  color: variables.$pill-green-text;
}
.rich-link .pill.red {
  background: variables.$pill-red-background;
  color: variables.$red-primary;
}
.rich-link .pill.blue {
  background: variables.$pill-blue-background;
  color: variables.$pill-blue-text;
}
.rich-link .pill.navy {
  background: variables.$gray-mid;
  color: variables.$navy-primary;
}
/* #endregion */

/* #region - STATUS RIBBON */
.rich-link .status {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  user-select: none;
}
.rich-link .status.red {
  background-color: variables.$red-primary;
}
.rich-link .status.yellow {
  background-color: variables.$yellow-primary;
}
.rich-link .status.green {
  background-color: variables.$green-primary;
}
.rich-link .status.blue {
  background-color: variables.$pill-blue-text;
}
.rich-link .status.gray {
  background-color: variables.$gray-primary !important;
}
/* #endregion */

/* #region - PROGRESS BAR */
.rich-link .progress-bar-container {
  display: block !important;
  margin-top: 5px;
  width: 100%;
  border-radius: 10px;
  height: 12px;
  background-color: variables.$gray-light;
  position: relative;
  overflow: hidden;
  line-height: 11px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: bold;
  user-select: none;
}

.rich-link .progress-bar-container .progress-bar-line {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: variables.$green-primary;
  text-align: center;
}

.rich-link .progress-bar-container .progress-bar-text {
  position: absolute;
  top: 0;
  bottom: 0;
  color: variables.$gray-text;
  display: inline-block;
  padding-left: 5px;
}
/* #endregion */

/* #region - ICONS */
/* NOTE: Just copying all this from FontAwesome CSS */
.rich-link .icon {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  text-align: center;
  width: 1.25em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  width: 18px;
  user-select: none;
}

.rich-link .icon.error::before {
  content: "\f00d";
}

.rich-link .icon.loading {
  -webkit-animation: rich-link-icon-spin 2s linear infinite;
  animation: rich-link-icon-spin 2s linear infinite;
}
.rich-link .icon.loading::before {
  content: "\f110";
  text-align: center;
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
}

@-webkit-keyframes rich-link-icon-spin {
  0% {
   -webkit-transform:rotate(0deg);
   transform:rotate(0deg);
  }
  to {
   -webkit-transform:rotate(1turn);
   transform:rotate(1turn);
  }
 }
 @keyframes rich-link-icon-spin {
  0% {
   -webkit-transform:rotate(0deg);
   transform:rotate(0deg);
  }
  to {
   -webkit-transform:rotate(1turn);
   transform:rotate(1turn);
  }
 }
/* #endregion  */

/*
NOTE: THIS IS A MASSIVE MASSIVE HACK BUT IT MEANS WE DON'T HAVE TO BYPASS
THE ANGULAR SANITIZER AND I'D RATHER DO THIS THAN BYPASS THAT :)
*/
/* #region - PROGRESS BAR HACKS */
.progress-0 { width: 0%; }
.progress-1 { width: 1%; }
.progress-2 { width: 2%; }
.progress-3 { width: 3%; }
.progress-4 { width: 4%; }
.progress-5 { width: 5%; }
.progress-6 { width: 6%; }
.progress-7 { width: 7%; }
.progress-8 { width: 8%; }
.progress-9 { width: 9%; }
.progress-10 { width: 10%; }
.progress-11 { width: 11%; }
.progress-12 { width: 12%; }
.progress-13 { width: 13%; }
.progress-14 { width: 14%; }
.progress-15 { width: 15%; }
.progress-16 { width: 16%; }
.progress-17 { width: 17%; }
.progress-18 { width: 18%; }
.progress-19 { width: 19%; }
.progress-20 { width: 20%; }
.progress-21 { width: 21%; }
.progress-22 { width: 22%; }
.progress-23 { width: 23%; }
.progress-24 { width: 24%; }
.progress-25 { width: 25%; }
.progress-26 { width: 26%; }
.progress-27 { width: 27%; }
.progress-28 { width: 28%; }
.progress-29 { width: 29%; }
.progress-30 { width: 30%; }
.progress-31 { width: 31%; }
.progress-32 { width: 32%; }
.progress-33 { width: 33%; }
.progress-34 { width: 34%; }
.progress-35 { width: 35%; }
.progress-36 { width: 36%; }
.progress-37 { width: 37%; }
.progress-38 { width: 38%; }
.progress-39 { width: 39%; }
.progress-40 { width: 40%; }
.progress-41 { width: 41%; }
.progress-42 { width: 42%; }
.progress-43 { width: 43%; }
.progress-44 { width: 44%; }
.progress-45 { width: 45%; }
.progress-46 { width: 46%; }
.progress-47 { width: 47%; }
.progress-48 { width: 48%; }
.progress-49 { width: 49%; }
.progress-50 { width: 50%; }
.progress-51 { width: 51%; }
.progress-52 { width: 52%; }
.progress-53 { width: 53%; }
.progress-54 { width: 54%; }
.progress-55 { width: 55%; }
.progress-56 { width: 56%; }
.progress-57 { width: 57%; }
.progress-58 { width: 58%; }
.progress-59 { width: 59%; }
.progress-60 { width: 60%; }
.progress-61 { width: 61%; }
.progress-62 { width: 62%; }
.progress-63 { width: 63%; }
.progress-64 { width: 64%; }
.progress-65 { width: 65%; }
.progress-66 { width: 66%; }
.progress-67 { width: 67%; }
.progress-68 { width: 68%; }
.progress-69 { width: 69%; }
.progress-70 { width: 70%; }
.progress-71 { width: 71%; }
.progress-72 { width: 72%; }
.progress-73 { width: 73%; }
.progress-74 { width: 74%; }
.progress-75 { width: 75%; }
.progress-76 { width: 76%; }
.progress-77 { width: 77%; }
.progress-78 { width: 78%; }
.progress-79 { width: 79%; }
.progress-80 { width: 80%; }
.progress-81 { width: 81%; }
.progress-82 { width: 82%; }
.progress-83 { width: 83%; }
.progress-84 { width: 84%; }
.progress-85 { width: 85%; }
.progress-86 { width: 86%; }
.progress-87 { width: 87%; }
.progress-88 { width: 88%; }
.progress-89 { width: 89%; }
.progress-90 { width: 90%; }
.progress-91 { width: 91%; }
.progress-92 { width: 92%; }
.progress-93 { width: 93%; }
.progress-94 { width: 94%; }
.progress-95 { width: 95%; }
.progress-96 { width: 96%; }
.progress-97 { width: 97%; }
.progress-98 { width: 98%; }
.progress-99 { width: 99%; }
.progress-100 { width: 100%; }

.progress-text-1 { left: 0%; }
.progress-text-1 { left: 1%; }
.progress-text-2 { left: 2%; }
.progress-text-3 { left: 3%; }
.progress-text-4 { left: 4%; }
.progress-text-5 { left: 5%; }
.progress-text-6 { left: 6%; }
.progress-text-7 { left: 7%; }
.progress-text-8 { left: 8%; }
.progress-text-9 { left: 9%; }
.progress-text-10 { left: 10%; }
.progress-text-11 { left: 11%; }
.progress-text-12 { left: 12%; }
.progress-text-13 { left: 13%; }
.progress-text-14 { left: 14%; }
.progress-text-15 { left: 15%; }
.progress-text-16 { left: 16%; }
.progress-text-17 { left: 17%; }
.progress-text-18 { left: 18%; }
.progress-text-19 { left: 19%; }
.progress-text-20 { left: 20%; }
.progress-text-21 { left: 21%; }
.progress-text-22 { left: 22%; }
.progress-text-23 { left: 23%; }
.progress-text-24 { left: 24%; }
.progress-text-25 { left: 25%; }
.progress-text-26 { left: 26%; }
.progress-text-27 { left: 27%; }
.progress-text-28 { left: 28%; }
.progress-text-29 { left: 29%; }
.progress-text-30 { left: 30%; }
.progress-text-31 { left: 31%; }
.progress-text-32 { left: 32%; }
.progress-text-33 { left: 33%; }
.progress-text-34 { left: 34%; }
.progress-text-35 { left: 35%; }
.progress-text-36 { left: 36%; }
.progress-text-37 { left: 37%; }
.progress-text-38 { left: 38%; }
.progress-text-39 { left: 39%; }
.progress-text-40 { left: 40%; }
.progress-text-41 { left: 41%; }
.progress-text-42 { left: 42%; }
.progress-text-43 { left: 43%; }
.progress-text-44 { left: 44%; }
.progress-text-45 { left: 45%; }
.progress-text-46 { left: 46%; }
.progress-text-47 { left: 47%; }
.progress-text-48 { left: 48%; }
.progress-text-49 { left: 49%; }
.progress-text-50 { left: 50%; }
.progress-text-51 { left: 51%; }
.progress-text-52 { left: 52%; }
.progress-text-53 { left: 53%; }
.progress-text-54 { left: 54%; }
.progress-text-55 { left: 55%; }
.progress-text-56 { left: 56%; }
.progress-text-57 { left: 57%; }
.progress-text-58 { left: 58%; }
.progress-text-59 { left: 59%; }
.progress-text-60 { left: 60%; }
.progress-text-61 { left: 61%; }
.progress-text-62 { left: 62%; }
.progress-text-63 { left: 63%; }
.progress-text-64 { left: 64%; }
.progress-text-65 { left: 65%; }
.progress-text-66 { left: 66%; }
.progress-text-67 { left: 67%; }
.progress-text-68 { left: 68%; }
.progress-text-69 { left: 69%; }
.progress-text-70 { left: 70%; }
.progress-text-71 { left: 71%; }
.progress-text-72 { left: 72%; }
.progress-text-73 { left: 73%; }
.progress-text-74 { left: 74%; }
.progress-text-75 { left: 75%; }
.progress-text-76 { left: 76%; }
.progress-text-77 { left: 77%; }
.progress-text-78 { left: 78%; }
.progress-text-79 { left: 79%; }
.progress-text-80 { left: 80%; }
.progress-text-81 { left: 81%; }
.progress-text-82 { left: 82%; }
.progress-text-83 { left: 83%; }
.progress-text-84 { left: 84%; }
.progress-text-85 { left: 85%; }
.progress-text-86 { left: 86%; }
.progress-text-87 { left: 87%; }
.progress-text-88 { left: 88%; }
.progress-text-89 { left: 89%; }
.progress-text-90 { left: 90%; }
.progress-text-91 { left: 91%; }
.progress-text-92 { left: 92%; }
.progress-text-93 { left: 93%; }
.progress-text-94 { left: 94%; }
.progress-text-95 { left: 95%; }
.progress-text-96 { left: 96%; }
.progress-text-97 { left: 97%; }
.progress-text-98 { left: 98%; }
.progress-text-99 { left: 99%; }
.progress-text-100 { left: 100%; }
/* #endregion */