@use 'variables';

// User feedback form
.sentry-error-embed-wrapper {
  .sentry-error-embed {
    padding: 15px;

    header {
      text-align: left;
      padding-bottom: 15px;
      margin-bottom: 15px;

      h1 {
        font-size: 24px;
        line-height: 40px;
      }

      p {
        margin: 0;
        font-size: 14px;
        color: variables.$gray-text-dark;
      }
    }

    form {
      .form-content {
        .form-field {
          margin-bottom: 15px;
        }
      }

      .form-submit {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        gap: 5px;

        button {
          font-size: 0;
          background: none;
          margin: 0;
          padding: 0;
          float: none;
          border: none;
          opacity: 1;
          box-shadow: none;


          &:focus,
          &:hover {
            background: none;

            &::after {
              box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.2);
            }
          }

          &:active {
            &::after {
              box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
            }
          }

          &::after {
            display: inline-block;
            margin-right: 0;
            float: none;

            text-align: center;
            border-radius: 40px;
            border-width: 1px;
            border-style: solid;
            padding: 5px 15px;
            white-space: nowrap;
            font-size: 16px;
            line-height: 18px;
            font-weight: 600;
            user-select: none;
            position: relative;
            cursor: pointer;
            box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
          }

          &.btn {
            &::after {
              color: variables.$white-primary;
              background-color: variables.$green-primary;
              border-color: variables.$green-primary;
              content: 'Submit';
            }
          }

          &.close {
            &::after {
              border-color: variables.$gray-text;
              color: variables.$gray-text;
              background-color: variables.$white-primary;
              content: 'Cancel';
            }
          }
        }

        .powered-by {
          display: none;
        }
      }
    }
  }
}