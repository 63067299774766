@use 'variables';

.card {
  // background-color: variables.$green-ultralight;
  background-color: variables.$white-primary;
  border-radius: 6px;
  // box-shadow: none;
  border-color: variables.$green-light;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 15px;

  border: none;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);

  &.card-yellow {
    background-color: variables.$pill-yellow-background;
  }

  &.card-green {
    background-color: variables.$pill-green-background;
  }

  &.card-red {
    background-color: variables.$pill-red-background;
  }

  &.card-blue {
    background-color: variables.$pill-blue-background;
  }

  &.card-navy {
    background-color: variables.$pill-navy-background;
  }

  &.card-gray {
    background-color: variables.$pill-gray-background;
  }

  &.card-offwhite {
    background-color: variables.$pill-offwhite-background;
  }

  &.no-margin {
    margin-bottom: 0;
  }
}
