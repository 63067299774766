@use 'variables';

.standard-description {
  font-size: 14px;
  color: variables.$gray-text-mid;
}

.standard-documentation-link {
  text-decoration: underline;
}

.text-success {
  color: variables.$green-contrast;
}

.text-warning {
  color: variables.$yellow-primary;
}

.text-danger {
  color: variables.$red-primary;
}


.font-weight-thin {
  font-weight: 100;
}

.font-weight-extra-light {
  font-weight: 200;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-extra-bold {
  font-weight: 800;
}

.font-weight-black {
  font-weight: 900;
}
