// GENERAL
.m-15-l-r {
  margin-left: 15px;
  margin-right: 15px;
}

.m-0 {
  margin: 0;
}

// TOP
.m-top-0 {
  margin-top: 0;
}

.m-top-5 {
  margin-top: 5px;
}

.m-top-7 {
  margin-top: 7px;
}

.m-top-10 {
  margin-top: 10px;
}

.m-top-15 {
  margin-top: 15px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-25 {
  margin-top: 25px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-top-40 {
  margin-top: 40px;
}

.m-top-50 {
  margin-top: 50px;
}

// LEFT
.m-left-minus-30 {
  margin-left: -30px;
}

.m-left-0 {
  margin-left: 0;
}

.m-left-5 {
  margin-left: 5px;
}

.m-left-10 {
  margin-left: 10px;
}

.m-left-15 {
  margin-left: 15px;
}

.m-left-20 {
  margin-left: 20px;
}

.m-left-30 {
  margin-left: 30px;
}

.m-left-40 {
  margin-left: 40px;
}

.m-left-50 {
  margin-left: 50px;
}

// RIGHT
.m-right-minus-30 {
  margin-right: -30px;
}

.m-right-0 {
  margin-right: 0px;
}

.m-right-5 {
  margin-right: 5px;
}

.m-right-10 {
  margin-right: 10px;
}

.m-right-15 {
  margin-right: 15px;
}

.m-right-20 {
  margin-right: 20px;
}

.m-right-25 {
  margin-right: 25px;
}

.m-right-30 {
  margin-right: 30px;
}

// BOTTOM
.m-bottom-5 {
  margin-bottom: 5px;
}

.m-bottom-10 {
  margin-bottom: 10px;
}

.m-bottom-15 {
  margin-bottom: 15px;
}

.m-bottom-20 {
  margin-bottom: 20px;
}

.m-bottom-25 {
  margin-bottom: 25px;
}

.m-bottom-30 {
  margin-bottom: 30px;
}

.m-bottom-50 {
  margin-bottom: 50px;
}

.m-bottom-0 {
  margin-bottom: 0;
}
