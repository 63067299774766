@media print {
  .visible-print,
  .visible-print-block {
    display: block !important;
  }

  .visible-print-inline {
    display: inline !important;
  }

  .visible-print-inline-block {
    display: inline-block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  td.visible-print,
  th.visible-print {
    display: table-cell !important;
  }

  .hidden-print {
    display: none !important;
  }

  .avoid-breaks-print {
    page-break-inside: avoid;
    break-inside: avoid;
    -webkit-break-inside: avoid;
  }

  .tooltip {
    display: none !important;
  }
}
