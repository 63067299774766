@use 'variables';

.frankli-table {
  padding: 5px;
  /* border-bottom: solid 1px rgba(63, 63, 63, 0.1); */
  line-height: 48px;
  position: relative;

  & > span,
  & > th {
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: normal;
  }

  & > .sorting,
  & > .sorting-ascending,
  & > .sorting-descending {
    cursor: pointer;
  }

  & > .sorting::after,
  & > .sorting-ascending::after,
  & > .sorting-descending::after {
    padding-left: 5px;
    opacity: 0.5;
    font-family: 'Font Awesome 5 Pro';
  }

  & > .sorting::after {
    content: "\f0dc";
  }

  & > .sorting-ascending::after {
    content: "\f0de";
  }

  & > .sorting-descending::after {
    content: "\f0dd";
  }

  &:not(.frankli-table-green)
  &:not(.frankli-table-yellow)
  &:not(.frankli-table-red) {
    &:not(:first-child) {
      &:nth-child(odd) {
        background-color: variables.$white-primary;
      }

      &:nth-child(even) {
        background-color: rgba(variables.$gray-primary, 0.1);
      }
    }
  }

  &.frankli-table-hover:hover {
    cursor: pointer;
    // background-color: variables.$gray-light !important;
    background-color: rgba(variables.$gray-primary, 0.2);
    // box-shadow: inset 0px 0px 15px 0px rgba(variables.$navy-primary, 0.1);
  }

  &.frankli-table-small {
    line-height: 28px;
  }

  &.frankli-table-active {
    min-height: 120px;
  }

  &.frankli-table-green {
    // background-color: variables.$green-primary;
    // color: variables.$white-primary;
    background-color: variables.$pill-green-background;
    color: variables.$pill-green-text;
  }

  &.frankli-table-red {
    // background-color: variables.$red-primary;
    // color: variables.$white-primary;
    background-color: variables.$pill-red-background;
    color: variables.$pill-red-text;
  }

  &.frankli-table-yellow {
    // background-color: variables.$yellow-primary;
    // color: variables.$white-primary;
    background-color: variables.$pill-yellow-background;
    color: variables.$pill-yellow-text;
  }

  &.frankli-table-header-large {
    margin-top: 5px;
  }

  &.frankli-table-header {
    padding-top: 0;
    padding-bottom: 5px;
    border-top: 0;
    color: variables.$gray-text-light;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 28px;
    border-top: variables.$gray-light 1px solid;
    border-bottom: variables.$gray-light 1px solid;
  
    & > th {
      padding-top: 5px;
      padding-bottom: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:first-child {
        padding-left: 15px;
      }
  
      &:last-child {
        padding-right: 15px;
      }
    }
  }
  
  &.frankli-table-single-line {
    & > div,
    & > td {
      // TODO: is this needed?
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 5px;
      padding-right: 5px;
    }

    & > td,
    & > th {
      line-height: 40px;
      padding: 10px;

      &:first-child {
        padding-left: 15px;
      }
  
      &:last-child {
        padding-right: 15px;
      }
    }

    .frankli-table-controls {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: unset;
      padding-left: 5px;
      padding-right: 5px;
    }

    .frankli-table-icon {
      text-overflow: unset;
    }
  }

  &.frankli-table-multi-line {
    & > div,
    & > td {
      overflow-x: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      padding-left: 5px;
      padding-right: 5px;
    }
  
    & > td,
    & > th {
      line-height: 28px;
      padding: 5px;
    }
  
    .frankli-table-controls {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: unset;
      padding-left: 5px;
      padding-right: 5px;
    }
    
    .frankli-table-icon {
      text-overflow: unset;
    }
  }

  .frankli-table-user {
    display: inline-block;
    height: 50px;
    width: 50px;
    border: solid 1px variables.$white-primary;
    border-radius: 50%;
    overflow: hidden;
    line-height: normal;
  }

  .action-icons {
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    /* position: sticky;
    right: 5px; */

    span.fal {
      text-align: center;
      width: 24px;
    }

    &::before {
      content: "";
      /* margin-left: 10px; */
    }
  }

  &.frankli-view,
  &.frankli-edit,
  &.frankli-close,
  &.frankli-archive {
    display: inline-block !important;
  }

  &.frankli-table-gray-background {
    background-color: variables.$gray-background;
  }
}
