// GENERAL
.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

// TOP
.p-top-0 {
  padding-top: 0px !important;
}

.p-top-5 {
  padding-top: 5px;
}

.p-top-10 {
  padding-top: 10px;
}

.p-top-15 {
  padding-top: 15px;
}

.p-top-20 {
  padding-top: 20px;
}

.p-top-25 {
  padding-top: 25px;
}

.p-top-30 {
  padding-top: 30px;
}

.p-top-50 {
  padding-top: 50px;
}

// BOTTOM
.p-bottom-0 {
  padding-bottom: 0px !important;
}

.p-bottom-5 {
  padding-bottom: 5px;
}

.p-bottom-10 {
  padding-bottom: 10px;
}

.p-bottom-15 {
  padding-bottom: 15px;
}

.p-bottom-20 {
  padding-bottom: 20px;
}

.p-bottom-25 {
  padding-bottom: 25px;
}

.p-bottom-30 {
  padding-bottom: 30px;
}

// LEFT
.p-left-0 {
  padding-left: 0px;
}

.p-left-5 {
  padding-left: 5px;
}

.p-left-10 {
  padding-left: 10px;
}

.p-left-15 {
  padding-left: 15px;
}

.p-left-20 {
  padding-left: 20px;
}

.p-left-25 {
  padding-left: 25px;
}

.p-left-30 {
  padding-left: 30px;
}

// RIGHT
.p-right-0 {
  padding-right: 0px;
}

.p-right-5 {
  padding-right: 5px;
}

.p-right-10 {
  padding-right: 10px;
}

.p-right-15 {
  padding-right: 15px;
}

.p-right-20 {
  padding-right: 20px;
}

.p-right-25 {
  padding-right: 25px;
}

.p-right-30 {
  padding-right: 30px;
}
