@use 'variables';

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: variables.$gray-mid;
  -webkit-transition: .4s;
  transition: .4s;
  
  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    top: 4px;
    left: 4px;
    bottom: 4px;
    background-color: variables.$white-primary;
    transition: all ease-in-out 250ms;
  }

  &.slider-disabled {
    opacity: 0.4;
    background-color: variables.$gray-ultralight !important;
    cursor: not-allowed;
  }

  &.round {
    border-radius: 34px;
  
    &:before {
      border-radius: 50%;
    }
  }
}

input {
  &:checked {
    & + .slider {
      background-color: variables.$green-primary;

      &:before {
        left: calc(100% - 21px);
      }
    }
  }

  &:focus {
    & + .slider {
      box-shadow: 0 0 1px variables.$green-primary;
    }
  }
}
