@use 'variables';

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

// TODO:
// Putting the styles here seems a bit weird.
// It's not required for some drag/drops like on the Review Cycles question bank
// but it is for others. Maybe there's a way to specify the root container so that
// it's placed in a div relative to the component instead of at the root html body

.cdk-drag-dragging {
  // Key results drag preview
  &.key-result-item {
    display: none !important;
    opacity: 1 !important;
    border-style: 1px solid red;
    border-width: 1px;
    border-color: variables.$gray-light;
    border-radius: 6px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
    background-color: variables.$white-primary;
    padding: 10px;
  
    .drag-handle {
      display: none;
    }
  
    .remove-result-icon {
      display: none;
    }
  }
}

.cdk-drag-preview {
  &.role-item-display {
    background-color: variables.$white-primary;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 15px;
    
    .role-drag-handle,
    .role-action-icons {
      display: none;
    }
  }
}