@use 'variables';

// NOTE: USE THE APP-BUTTON COMPONENT INSTEAD OF THESE

.btn-frankli-warning {
  opacity: 1;
  border-color: variables.$yellow-primary;
  color: variables.$white-primary;
  background-color: variables.$yellow-primary;

  &:hover {
    border-color: #FCC559;
    color: variables.$white-primary;
    background-color: #FCC559;
  }

  &:disabled {
    background-color: variables.$yellow-primary;
    &:hover {
      background-color: variables.$yellow-primary;
    }
  }

  &:focus {
    background-color: #FCC559;
    color: variables.$white-primary;
  }
  
  &:active {
    background-color: #FCC559;
    color: variables.$white-primary;
  }
}

.btn-frankli-purple-o {
  border-color: variables.$navy-primary !important;
  color: variables.$navy-primary !important;
  background-color: transparent !important;
  
  &:hover {
    border-color: variables.$navy-primary !important;
    color: variables.$navy-primary !important;
  }
}

.btn-frankli-round {
  text-align: center;
  opacity: 1;
  display: inline-block;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  min-width: 100px;
  cursor: pointer;
  border: 1px transparent solid;
  border-radius: 20px;

  &:disabled {
    opacity: 0.5;
  }
}

.btn-frankli-small {
  font-size: 14px;
}

.font-weight-bolder {
  font-weight: bolder
}

.btn-frankli-transparent {
  background-color: transparent !important;

  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: variables.$white-primary !important;
    }
  }
}

.btn-frankli-gray {
  border-color: #A3A5AA;
  color: variables.$white-primary;
  background-color: #A3A5AA;

  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: #a9abad;
    }
  }

}

.btn-frankli-gray-inverted {
  border-color: #A3A5AA;
  color: #A3A5AA;
  background-color: variables.$white-primary;

  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: variables.$gray-light;
    }
  }
}

.btn-frankli-cancel {
  border-color: #A3A5AA;
  color: variables.$gray-cancel-text;
  background-color: variables.$white-primary;

  &:focus,
  &:hover {
    &:not(:disabled) {
      border-color: variables.$green-primary;
      color: variables.$green-primary;
    }
  }
}

.btn-frankli-green {
  border-color: variables.$green-primary;
  color: variables.$white-primary !important;
  background-color: variables.$green-primary;

  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: variables.$green-contrast;
    }
  }
}

.btn-frankli-red {
  border-color: variables.$red-primary;
  color: variables.$white-primary !important;
  background-color: variables.$red-primary;

  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: rgb(219, 80, 80);
    }
  }
}

.btn-frankli-red-inverted {
  border-color: variables.$red-primary;
  color: variables.$red-primary !important;
  background-color: variables.$white-primary;

  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: rgb(238, 238, 238);
    }
  }
}

.btn-frankli-green-inverted {
  border-color: variables.$green-primary;
  color: variables.$green-contrast;
  background-color: variables.$white-primary;

  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: rgb(238, 238, 238);
    }
  }
}

.btn-frankli-navy-inverted {
  border-color: variables.$navy-primary;
  color: variables.$navy-primary;
  background-color: variables.$white-primary;

  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: rgb(238,  238, 238);
    }
  }
}

.btn-frankli-add {
  border: 2px solid variables.$green-primary;
  background: variables.$white-primary;
  color: variables.$green-primary;
  border-radius: 2px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    background: variables.$green-primary;
    color: variables.$white-primary;
  }
}
