.lh-10 {
  line-height: 10px;
}

.lh-20 {
  line-height: 20px;
}

.lh-20i {
  line-height: 20px !important;
}

.lh-30 {
  line-height: 30px;
}

.lh-40 {
  line-height: 40px;
}

.lh-50 {
  line-height: 50px;
}

.lh-60 {
  line-height: 60px;
}

.lh-normal {
  line-height: normal;
}