@use 'variables';

.frankli-view,
.frankli-create,
.frankli-edit,
.frankli-close,
.frankli-delete,
.frankli-archive {
  cursor: pointer;
}

.frankli-view:hover,
.frankli-view:focus,
.frankli-create:hover,
.frankli-create:focus {
  color: variables.$green-contrast !important
}

.frankli-edit:hover,
.frankli-edit:focus {
  color: variables.$yellow-primary !important;
}

.frankli-close:hover,
.frankli-close:focus,
.frankli-delete:hover,
.frankli-delete:focus {
  color: variables.$red-primary !important;
}

.frankli-archive:hover,
.frankli-archive:focus {
  color: variables.$navy-primary !important;
}

.expand-on-hover {
  &:not(.fa-2x) {
    line-height: 0.25em;
    transition: font-size 0.2s;
    vertical-align: middle;
    height: 0.5em;
    overflow: visible;
    display: inline-block;
    min-width: 21px;

    &:hover,
    &:focus {
      font-size: 1.2em;
    }
  }

  &.fa-2x {
    padding-top: 0.25em;
    line-height: 0.25em;
    transition: font-size 0.2s;
    vertical-align: middle;
    height: 0.5em;
    overflow: visible;

    &:hover,
    &:focus {
      font-size: 2.2em;
    }
  }
}
