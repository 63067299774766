@use 'variables';

.tooltip {
  padding: 10px;
  z-index: 1601;

  /* Show alt arrow */
  .tooltip-arrow {
    display: block;
    opacity: 1;
    border: unset;

    &::before,
    &::after {
      content: '';
      position: absolute;
      border: unset;
      border-width: 11px;
      border-style: solid;
    }

    &::before {
      border-color: rgba(0, 0, 0, 0.2);
    }

    &::after {
      border-color:white;
    }
  }

  .tooltip-inner {
    background-color: variables.$white-primary;
    color: variables.$gray-text;
    border: 1px solid white;
    font-weight: 500;
    padding: 3px;
    font-size: 13px;
    min-width: unset;
    // max-width: unset;
    max-width: 400px;
  
    /* Hide base arrow */
    &:before,
    &:after {
      opacity: 0;
    }
  }  

  &.top {
    .tooltip-arrow {
      &::before,
      &::after {
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        left: 50%;
        transform: translateX(-50%);
      }

      &::before {
        bottom: -23px;
      }
      &::after {
        bottom: -21.5px;
      }
    }
  }

  &.bottom {
    .tooltip-arrow {
      &::before,
      &::after {
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        left: 50%;
        transform: translateX(-50%);
      }

      &::before {
        top: -23px;
      }
      &::after {
        top: -21.5px;
      }
    }
  }

  &.left {
    .tooltip-arrow {
      &::before,
      &::after {
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        right: -23px;
      }
      &::after {
        right: -21.5px;
      }
    }
  }

  &.right {
    .tooltip-arrow {
      &::before,
      &::after {
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        border-left-color: transparent !important;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        left: -23px;
      }
      &::after {
        left: -21.5px;
      }
    }
  }
}

/* Box styling */

/* #region - Tooltip custom colors */
.frankli-tooltip-warning {
  .tooltip-inner {
    background: variables.$pill-yellow-background !important;
  }

  .tooltip-arrow::after {
    border-color: variables.$pill-yellow-background;
  }  
} 
