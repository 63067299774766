.min-width-100 {
  min-width: 100px;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-300 {
  min-width: 300px;
}

.min-width-400 {
  min-width: 400px;
}

.min-width-500 {
  min-width: 500px;
}
