.d-flex {
  display: flex;
}

/* flex direction utilities */
.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

/* alignment utilities */

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

/* flex grow utilities */

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}